<template>
  <div class="pa-2">
    <v-card class="pa-2 mb-2" flat :loading="loadingstatus">
        <v-card-actions>
            <v-spacer></v-spacer>
             <v-btn medium color="primary" @click="btnsearch" class="mr-1">
            <v-icon left>mdi-database-refresh</v-icon>LOAD DATA</v-btn
          >

<export-excel
            v-if="desserts.length > 0"
            :data="desserts"
            type="button"
          >
            <v-btn color="success"
              ><v-icon left>mdi-microsoft-excel</v-icon>DOWNLOAD</v-btn
            >
          </export-excel>
        </v-card-actions>
     
    </v-card>
    
    <v-card class="pa-1 mb-2" flat v-if="desserts.length > 0" >
      <v-data-table
      
        v-model="TDselected"
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="application_id"
        
        style="border-radius: 0px"
      >
        <!-- @click="BtnApprove(index)" -->
        <templete v-slot:item.application_id="{ item }">
          <td class="tdalign">{{ item.s_no }}</td>
          <td class="tdalign">{{ item.app_id }}</td>
          <td class="tdalign">{{ item.company_name }}</td>
          <td class="tdalign">{{ item.fName }}</td>
          <td class="tdalign">{{ item.lName }}</td>
          
          <td class="tdalign">{{ item.mobile }}</td>
          <td class="tdalign">{{ item.email }}</td>

          <td class="tdalign">{{ item.anyMedicalCondition }}</td>
          <td class="tdalign">{{ item.medicalCondition }}</td>
          <td class="tdalign">{{ item.isVaccinated }}</td>
          <td class="tdalign">{{ item.vaccineTaken }}</td>
          <td class="tdalign">{{ item.vaccineCountry }}</td>
          <td class="tdalign">{{ item.firstDose }}</td>
          <td class="tdalign">{{ item.secondDose }}</td>
          
        </templete>

        <template v-slot:no-data>
          <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
          No Record(s)
        </template>
      </v-data-table>
    </v-card>
    

    <v-snackbar v-model="snackbar" shaped top :color="snackbarcolor">
      {{ snackbartext }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
//import axios from "axios";
//import moment from "moment";
//import ViewApplication from "../../components/ViewApplication.vue";
export default {
  data() {
    return {
     
      TDselected: [],
      singleSelect: false,
      selected: [],
      chkselected: [],
      cApp: {},
      appstatus: "none",
      cAppIndex: 0,
      date: new Date().toISOString().substr(0, 10),
      time: "",
      datebulk: new Date().toISOString().substr(0, 10),
      timebulk: "",
      isViewAppOpened: false,
      modal2: false,
      modal: false,
      overlay: false,
      modaldatebulk: false,
      modaltimebulk: false,
      menu: false,
      menu2: false,
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      EntryMode: "",
      valid: false,
      validbulk: false,
      mode: "NEW",
      newmode: true,
      sheet: true,
      sheetbulk: false,
      search: "",
      loadingstatus: false,
      cardID: "",
      Selcompanytype: "",
      event_id: "",

      Selstatus: "8",

      SelstatusForUpade: "",

      SelReasonForUpdate: "",
      ResonForUpdate: [],

      SelCardTypeForUpdate: "",
      CardTypeForUpdate: [],
      strcheckatatus: "",

      SelCompany: "",
      Company: [],

      //companytype: [],
      companytype_id: [],
      ReportFor:"",

      headers: [
        {
          text: "S_no",
          align: "center",
          value: "s_no",
          class: "blue lighten-5",
          width:"100px",
        },

        {
          text: "App-ID",
          value: "app_id",
          align: "center",
          width:"100px",
          class: "blue lighten-5",
        },

          {
          text: "Company",
          value: "company_name",
          align: "center",
          class: "blue lighten-5",
          width:"100px",
        },

        {
          text: "First Name",
          value: "fname",
          align: "center",
          class: "blue lighten-5",
          width:"100px",
        },

        {
          text: "Last Name",
          value: "lname",
          align: "center",
          class: "blue lighten-5",
          width:"100px",
        },

         {
          text: "Mobile",
          value: "mobile",
          align: "center",
          class: "blue lighten-5",
          width:"100px",
        },
        {
          text: "Email",
          value: "email",
          align: "center",
          class: "blue lighten-5",
          width:"100px",
        },

        {
          text: "Any Medical Condition",
          value: "anyMedicalCondition",
          align: "center",
          class: "blue lighten-5",
          width:"100px",
        },
        {
          text: "Medical Condition",
          value: "medicalCondition",
          align: "center",
          class: "blue lighten-5",
        },
        {
          text: "Is Vaccinated",
          value: "isVaccinated",
          align: "center",
          class: "blue lighten-5",
        },
        {
          text: "Vaccine Taken",
          value: "vaccineTaken",
          align: "center",
          class: "blue lighten-5",
        },

         {
          text: "Vaccine Country",
          value: "vaccineCountry",
          align: "center",
          class: "blue lighten-5",
        },

         {
          text: "First Dose",
          value: "firstDose",
          align: "center",
          class: "blue lighten-5",
        },

         {
          text: "Second Dose",
          value: "secondDose",
          align: "center",
          class: "blue lighten-5",
        },
        
      ],
      desserts: [],
      Vcompany_name: [
        (v) => !!v || "Company Name is required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vdatebulk: [
        (v) => !!v || "Date required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vtimebulk: [
        (v) => !!v || "Time required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
    };
  },
  mounted() {
    sessionStorage.display_event_name = "IPL T20 2021";
    sessionStorage.display_venue_name = "Chinaswamy Stadium";

    this.$store.commit("setPageTitle", "Covid Vacine Detail");
    //this.$store.commit("setEventName", sessionStorage.display_event_name);
    //this.$store.commit("setVenueName", sessionStorage.display_venue_name);

    // this.bindStatus();
    this.bindCartype(this.$store.state.eventInfo.EventId);
    this.bindCompany(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId
    );
    //  this.bindReson("1");
  },
  methods: {
    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },

    // watch: {
    //   chkselected: function () {
    //     // this.sheet=true;
    //     if (this.chkselected.length > 0) {
    //       alert("greater");
    //     }
    //   },
    // },

    chkall: function () {
      alert("test");
    
    },

   

    btnsearch: function () {
     
      this.Reload(this.$store.state.eventInfo.EventId,this.$store.state.eventInfo.VenueId,"1");
      
    },

    bindCompany: async function (EventID, VenueID) {
      await this.$axios
        .get("vendor/GetByEventId/" + EventID + "/" + VenueID)
        .then((res) => {
          if (res.data.result.length > 0) {
            this.Company = res.data.result;
            console.log(res.data.result);
          }
        });
    },

    bindCartype: async function (event_id) {
      await this.$axios
        .get("Cards/GetByEventId/" + event_id)
        .then((res) => {
          this.CardTypeForUpdate = res.data.result;
        });
    },

    bindStatus: async function () {
      await this.$axios
        .get("Status")
        .then((res) => {
          this.Status = res.data.result;
          this.StatusForUpdate = res.data.result;
          console.log(this.StatusForUpdate);
        });
    },

    Reload: async function (event_id,venue_id,vendor_id) {
      this.loadingstatus = true;
       
      await this.$axios
        .get(
          "Report/Covid/" + event_id + "/" + venue_id + "/" + vendor_id + "/" + this.$store.state.eventInfo.venuetype + "/" + this.$store.state.eventInfo.EventAdminID
        )
        .then((res) => {
          console.log(res.data.result);
          this.desserts = res.data.result;

          this.loadingstatus = false;
        })
        .catch()
        .finally();
    },
  },
};
</script>

<style  scoped>
.tdalign {
    text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
}
</style>>

